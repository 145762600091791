import classNames from "classnames";
import {
  type ImgHTMLAttributes,
  useLayoutEffect,
  useRef,
  useState,
} from "react";

export const Image = ({
  className,
  imageClassName,
  onLoad,
  loading = "eager",
  ...rest
}: { imageClassName?: string } & ImgHTMLAttributes<HTMLImageElement>) => {
  const [isLoaded, setIsLoaded] = useState(false);

  const ref = useRef<HTMLImageElement>(null);
  useLayoutEffect(() => {
    if (ref.current?.complete) setIsLoaded(true);
  }, [setIsLoaded]);

  // XXX: for the lazy load to work, the image cannot have a width and height that are 0. Also, it cannot be hidden.
  // We add a wrapper div to be able to add the loader in absolute above the lazy loading image.
  return (
    <div
      className={classNames(
        "relative overflow-hidden",
        !isLoaded ? "aspect-square" : "",
        className,
      )}
    >
      {!isLoaded && (
        <div className="h-full w-full absolute top-0 bg-gray-300 animate-pulse" />
      )}
      <img
        ref={ref}
        className={classNames(
          imageClassName,
          !isLoaded ? "h-10 w-10 invisible" : "",
        )}
        alt=""
        loading={loading}
        onLoad={(event) => {
          setIsLoaded(true);
          onLoad?.(event);
        }}
        {...rest}
      />
    </div>
  );
};
