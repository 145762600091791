import { useEffect, useRef, useState } from "react";
import { z } from "zod";
import { Form } from "../../../../../../components/Form/Form.tsx";
import { useForm } from "../../../../../../components/Form/FormContext.ts";
import { useUser } from "../../../../../../hooks/useUser.ts";
import { useAppQuery } from "../../../../../../http/useAppQuery.ts";
import { isUserAllowedTo } from "../../../../../../types.ts";
import { addNullInput } from "../../../../../../utils/validations.ts";
import { useBoard } from "../../../../hooks/useBoard.ts";
import type { ImageContent } from "../../../../types.ts";
import { scrollCreateWorkspaceGridToTop } from "../../../AssetsWorkspace/utils/scrollCreateWorkspaceGridToTop.ts";
import { GenerationBar } from "../../../components/GenerationBar/GenerationBar.tsx";
import { CollapsableSettingsSectionWrapper } from "../../../components/SettingsMenu/CollapsableSettingsSectionWrapper.tsx";
import { QualityPresetWrappedSection } from "../../../components/SettingsMenu/QualityPresetWrappedSection.tsx";
import {
  controlEndToCreativity,
  creativityToControlEnd,
  inputColorPercentageToPromptStrength,
  promptStrengthToInputColorPercentage,
  scaleControlStrength,
  unscaleControlStrength,
} from "../../../components/SettingsMenu/settingsScaleUtils.ts";
import { SettingsSectionWrapper } from "../../../components/SettingsMenu/SettingsSectionWrapper.tsx";
import { SliderSection } from "../../../components/SettingsMenu/SliderSection.tsx";
import { StyleIntensitySection } from "../../../components/SettingsMenu/StyleIntensitySection.tsx";
import { SettingsMenuLayout } from "../../../components/SettingsMenuLayout.tsx";
import { useStyleTransferGeneration } from "./hooks/useStyleTransferMutation.ts";
import { useStyleTransferSettings } from "./hooks/useStyleTransferSettings.ts";
import { InitialImageWrappedSection } from "./InitialImageWrappedSection.tsx";
import { InitImageColorInfluenceSection } from "./InitImageColorInfluenceSection.tsx";
import { styleTransferBaseImageStore } from "./stores/styleTransferBaseImageStore.ts";
import { transferPromptStore } from "./stores/transferPromptStore.ts";
import { TransferStrengthSection } from "./TransferStrengthSection.tsx";

export const StyleTransferSettings = () => {
  const { mutation: transferStyle, isLoading } = useStyleTransferGeneration({
    onSuccess: scrollCreateWorkspaceGridToTop,
  });

  return (
    <Form
      className="flex-col flex-fill"
      schema={zStyleTransferSettings}
      initialValues={{
        styleTransferBaseImageUuid: "",
        prompt: "",
        selectedStyleUuid: null,
      }}
      onSubmit={(values) => {
        // FIXME: make the mutation async
        transferStyle({
          prompt: values.prompt,
          initImageUuid: values.styleTransferBaseImageUuid,
          styleUuid: values.selectedStyleUuid,
        });
        return Promise.resolve(true);
      }}
    >
      <StyleTransferSettingsFormContent isGenerationLoading={isLoading} />
    </Form>
  );
};

const zStyleTransferSettings = z.object({
  styleTransferBaseImageUuid: z
    .string({ required_error: "Please add an initial image" })
    .uuid(),

  selectedStyleUuid: addNullInput(z.string().uuid(), "Please select a style"),
  prompt: z.string().min(1, { message: "Please enter a description" }),
});
type StyleTransferSettingsValues = z.input<typeof zStyleTransferSettings>;

const StyleTransferSettingsFormContent = ({
  isGenerationLoading,
}: {
  isGenerationLoading: boolean;
}) => {
  const { board } = useBoard();
  const { styleTransferSettings, setStyleTransferSettings } =
    useStyleTransferSettings();
  const { prompt } = transferPromptStore.useState();
  const { styleTransferBaseImageUuid } = styleTransferBaseImageStore.useState();
  const { user } = useUser();

  const [advancedSectionOpen, setAdvancedSectionOpen] = useState(false);
  useEffect(() => {
    if (styleTransferSettings.transfer_strength_preset === "custom") {
      setAdvancedSectionOpen(true);
    }
  }, [styleTransferSettings.transfer_strength_preset]);

  const { data: initImage } = useAppQuery<ImageContent>({
    queryKey: styleTransferBaseImageUuid
      ? `contents/${styleTransferBaseImageUuid}`
      : null,
  });

  const { setValues, submit, useError } =
    useForm<StyleTransferSettingsValues>();
  const missingStyleError = useError((v) => v.selectedStyleUuid);

  useEffect(() => {
    setValues({ prompt });
  }, [setValues, prompt]);

  useEffect(() => {
    setValues({ selectedStyleUuid: board.last_used_style_uuid });
  }, [setValues, board.last_used_style_uuid]);

  useEffect(() => {
    setValues({ styleTransferBaseImageUuid });
  }, [setValues, styleTransferBaseImageUuid]);

  const previousImageUuid = useRef<string>();
  const [isWaitingForDescription, setIsWaitingForDescription] = useState(false);

  useEffect(() => {
    // XXX: the description and its loading state updates differently if the user selects another image.
    // To detect if the user has changed the selected image we use a ref previousImageUuid.
    // Case where the user is still on the same image:
    if (previousImageUuid.current === initImage?.uuid) {
      if (isWaitingForDescription && initImage?.description) {
        transferPromptStore.setPrompt(initImage.description);
        setIsWaitingForDescription(false);
      }
    }
    // Case where the user selects another image:
    else {
      previousImageUuid.current = initImage?.uuid;
      if (initImage?.description) {
        setIsWaitingForDescription(false);
      } else if (!initImage?.uuid) {
        setIsWaitingForDescription(false);
      } else {
        setIsWaitingForDescription(true);
      }
    }
  }, [
    initImage?.description,
    initImage?.uuid,
    isWaitingForDescription,
    setValues,
  ]);

  return (
    <SettingsMenuLayout
      body={
        <div className="flex-col">
          <InitialImageWrappedSection
            imageUuid={styleTransferBaseImageUuid}
            onImageUuidChange={(uuid) =>
              styleTransferBaseImageStore.setStyleTransferBaseImage({
                styleTransferBaseImageUuid: uuid,
              })
            }
            error={useError((v) => v.styleTransferBaseImageUuid)}
          />
          <SettingsSectionWrapper
            name="Input type"
            content={<InitImageColorInfluenceSection />}
          />
          <SettingsSectionWrapper
            name="Transfer Strength"
            content={<TransferStrengthSection />}
            infoContent={
              <div className="flex-col gap-lg">
                <div>
                  <span className="font-bold">Light</span> - Adapted when your
                  initial image is quite close to your style (ex : a realistic
                  photo you want to convert into a precise drawing).
                </div>
                <div>
                  <span className="font-bold">Strong</span> - Adapted if your
                  initial image is very different from your style (ex: a
                  realistic photo you want to convert into an icon).
                </div>
              </div>
            }
          />
          {user && isUserAllowedTo(user, "mode:debug") && (
            <QualityPresetWrappedSection
              value={styleTransferSettings.quality_preset}
              onChange={(preset) =>
                setStyleTransferSettings({ quality_preset: preset })
              }
            />
          )}
          <CollapsableSettingsSectionWrapper
            name="Advanced"
            open={advancedSectionOpen}
            onOpenChange={setAdvancedSectionOpen}
            content={
              <div className="flex-col">
                <StyleIntensitySection
                  loraScale={styleTransferSettings.lora_scale}
                  onLoraScaleChange={(loraScale: number) =>
                    setStyleTransferSettings({
                      lora_scale: loraScale,
                      transfer_strength_preset: "custom",
                    })
                  }
                />
                <SliderSection
                  min={0}
                  max={100}
                  value={promptStrengthToInputColorPercentage(
                    styleTransferSettings.prompt_strength,
                  )}
                  onChange={(inputColorPercentage) =>
                    setStyleTransferSettings({
                      prompt_strength:
                        inputColorPercentageToPromptStrength(
                          inputColorPercentage,
                        ),
                      transfer_strength_preset: "custom",
                      init_image_color_influence: "color",
                    })
                  }
                  sliderName="Input colors"
                  sliderInformationSection={
                    <div className="flex-col gap-lg">
                      <div>
                        <span className="font-bold">Input colors</span> - The
                        higher this parameter, the more the colors of your input
                        image will be taken into account. Select 0% if you don’t
                        want the colors of your image input to influence your
                        results.
                      </div>
                    </div>
                  }
                />
                <SliderSection
                  min={0}
                  max={100}
                  value={scaleControlStrength(
                    styleTransferSettings.controlnet_1_conditioning_scale,
                  )}
                  onChange={(value) =>
                    setStyleTransferSettings({
                      controlnet_1_conditioning_scale:
                        unscaleControlStrength(value),
                      transfer_strength_preset: "custom",
                    })
                  }
                  sliderName="Input structure"
                  sliderInformationSection={
                    <div className="flex-col gap-lg">
                      <span>
                        <span className="font-bold">Input structure</span> - The
                        higher this parameter, the more the structure and shapes
                        of your initial image will be taken into account.
                      </span>
                    </div>
                  }
                />
                <SliderSection
                  min={0}
                  max={100}
                  value={Math.round(
                    controlEndToCreativity(
                      styleTransferSettings.controlnet_1_end,
                    ),
                  )}
                  onChange={(value) => {
                    setStyleTransferSettings({
                      controlnet_1_end: creativityToControlEnd(value),
                      transfer_strength_preset: "custom",
                    });
                  }}
                  sliderName="Creativity"
                  sliderInformationSection={
                    <div className="flex-col py-sm gap-lg">
                      <div>
                        <span className="font-bold">Creativity</span> - The
                        higher this parameter, the more freedom you will give to
                        Pimento to transfer your style.
                      </div>
                    </div>
                  }
                />
              </div>
            }
          />
        </div>
      }
      footer={
        <GenerationBar
          onGenerate={submit}
          isLoading={isGenerationLoading}
          prompt={prompt}
          setPrompt={(newPrompt) => {
            transferPromptStore.setPrompt(newPrompt);
          }}
          promptError={useError((v) => v.prompt)}
          isPromptLoading={isWaitingForDescription}
          onPromptLoadingChange={(loading) => {
            setIsWaitingForDescription(loading);
            if (!loading) transferPromptStore.setPrompt("");
          }}
          sectionTitle="Image description"
          buttonContent="Transfer style"
          autoFocus
          extraErrors={missingStyleError ? [missingStyleError] : undefined}
          extraCaption={
            <div className="text-gray-500">
              Transfer is in beta, results quality may vary
            </div>
          }
        />
      }
    />
  );
};
