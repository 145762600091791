import classNames from "classnames";
import { useMemo, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import Resizer from "react-image-file-resizer";
import { Button } from "../../../components/Button/Button.tsx";
import { Icon } from "../../../components/Icon/Icon.tsx";
import { Image } from "../../../components/Image/Image.tsx";
import { Progress } from "../../../components/Progress/Progress.tsx";
import { useOnMount } from "../../../hooks/useOnMount.ts";
import { useAppMutation } from "../../../http/useAppMutation.ts";
import { loadImage } from "../../../utils/image.ts";
import { notifier } from "../../../utils/notifier.ts";
import { pseudoRandomUUID } from "../../../utils/stackoverflow.ts";
import type { Style } from "../../types.ts";
import { ModelCreationBackButton } from "../components/ModelCreationBackButton.tsx";
import { ModelCreationCloseButton } from "../components/ModelCreationCloseButton.tsx";
import { StyleCreationImageTile } from "../components/StyleCreationImageTile.tsx";
import { TipsSection } from "../components/TipsSection.tsx";
import { useAddImageOnStyle } from "../hooks/useAddImageOnStyle.ts";

export const TrainingImagesForm = ({
  widthClassName,
  style,
  onClickPrevious,
}: {
  widthClassName?: string;
  style: Style;
  onClickPrevious: () => void;
}) => {
  const [uploadingImages, setUploadingImages] = useState<
    { fileToUpload: File; uuid: string }[]
  >([]);

  const minImageSideDimension = 256;

  const { mutate: updateStyle } = useAppMutation({
    path: "styles/update",
    invalidate: ["styles", `styles/${style.uuid}`],
  }).mutation;

  const { getRootProps, getInputProps, open } = useDropzone({
    multiple: true,
    noClick: true,
    accept: {
      "image/png": [".png"],
      "image/jpeg": [".jpeg", ".jpg"],
    },
    onDrop: (acceptedFiles) => {
      acceptedFiles.map(
        (acceptedFile) =>
          void loadImage(URL.createObjectURL(acceptedFile)).then((image) => {
            if (
              image.width < minImageSideDimension ||
              image.height < minImageSideDimension
            ) {
              notifier.error(
                `Your image dimensions should be greater than ${minImageSideDimension}x${minImageSideDimension} pixels`,
              );
            } else {
              setUploadingImages((current) =>
                current.concat({
                  uuid: pseudoRandomUUID(),
                  fileToUpload: acceptedFile,
                }),
              );
            }
          }),
      );
    },
  });

  const hasNotEnoughImages = useMemo(
    () => style.training_images.length < 4,
    [style],
  );

  const uploadButtonRef = useRef<HTMLDivElement>(null);
  return (
    // FIXME: find a way to extract the fullscreen drop zone for better modularisation
    <div className="relative flex-col-center flex-fill" {...getRootProps()}>
      <ModelCreationBackButton
        onClick={() => {
          updateStyle({
            uuid: style.uuid,
            type: null,
          });
        }}
        className="absolute top-16 left-16"
      />
      <ModelCreationCloseButton className="absolute top-16 right-16" />
      <div className="flex-col w-full flex-fill pt-[80px] pb-[30px] gap-[40px] overflow-y-auto items-center">
        <div className="flex-col gap-xl">
          <div className="flex-col items-center">
            <span className="text-[38px] text-gray-900 font-burg text-center">
              upload at least 10 pictures to create your model
            </span>
            <span className="text-xl text-gray-400">
              You can upload less, but no wow effect guaranteed!
            </span>
          </div>
        </div>
        <TipsSection styleType={style.type ? style.type : undefined} />
        <div className={classNames("flex-col gap-2xl", widthClassName)}>
          {(style.training_images.length > 0 || uploadingImages.length > 0) && (
            <div className="flex-shrink overflow-y-auto">
              <div className="grid lg:grid-cols-3 2xl:grid-cols-4 justify-items-center gap-2xl min-w-[120px] w-full">
                {uploadingImages.map((uploadingImage) => {
                  const clearAndScrollToBottom = () => {
                    setUploadingImages((current) =>
                      current.filter((d) => d.uuid !== uploadingImage.uuid),
                    );
                    uploadButtonRef.current?.scrollIntoView({
                      behavior: "smooth",
                    });
                  };
                  return (
                    <TrainingImage
                      key={uploadingImage.uuid}
                      file={uploadingImage.fileToUpload}
                      styleUuid={style.uuid}
                      onUploaded={clearAndScrollToBottom}
                    />
                  );
                })}
                {style.training_images.map((image) => (
                  <StyleCreationImageTile
                    key={image.uuid}
                    innerClassName="rounded-sm"
                    className="aspect-square h-[132px] w-[132px]"
                    image={image}
                    styleUuid={style.uuid}
                  />
                ))}
              </div>
            </div>
          )}
          {style.training_images.length > 0 &&
            style.training_images.length < 10 && (
              <div className="flex-row items-center gap-md self-start font-semibold">
                <Icon
                  name="Info"
                  className="mb-2 stroke-pimento-blue stroke-xl"
                  size={16}
                />
                <span className="text-pimento-blue">
                  More than 10 pictures lead to better results
                </span>
              </div>
            )}
        </div>
        <div
          ref={uploadButtonRef}
          className={classNames("flex-grow max-h-[250px]", widthClassName)}
        >
          <input {...getInputProps()} />
          <button
            type="button"
            className="h-full w-full flex-col-center gap-xl py-[37px] px-[30px] rounded-md border border-dashed border-pimento-blue bg-pimento-blue bg-opacity-5"
            onClick={() => open()}
          >
            <Icon
              name="ImagePlus"
              size={28}
              className="stroke-gray-900 stroke-[1.2px]"
            />
            <span className="text-lg font-semibold text-gray-900">
              <span className="text-pimento-blue underline">Browse files</span>
              &nbsp;or drop images
            </span>
          </button>
        </div>
        <Button
          className={widthClassName}
          disabled={hasNotEnoughImages}
          onClick={onClickPrevious}
        >
          {hasNotEnoughImages ? "Upload at least 4 images" : "Next"}
        </Button>
      </div>
    </div>
  );
};

const TrainingImage = ({
  file,
  styleUuid,
  onUploaded,
  onSuccess,
}: {
  file: File;
  styleUuid: string;
  onUploaded: () => void;
  onSuccess?: (image_uuid: string) => void;
}) => {
  const [src] = useState(() => URL.createObjectURL(file));
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  const { mutate: uploadImage, progress } = useAddImageOnStyle({
    styleUuid,
    onUploaded,
    onSuccess,
  });

  useOnMount(() => {
    Resizer.imageFileResizer(
      file,
      1024,
      1024,
      "JPEG",
      100,
      0,
      (blob) => {
        uploadImage({ image: blob as Blob });
      },
      "blob",
    );
  });

  return (
    <div className="rounded overflow-hidden aspect-square h-[132px] w-[132px]">
      <Image
        src={src}
        className="opacity-60 h-full w-full"
        imageClassName="m-auto object-cover object-center"
        onLoad={() => setIsImageLoaded(true)}
      />
      {isImageLoaded && <Progress value={progress} />}
    </div>
  );
};
