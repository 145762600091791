import { useEffect, useMemo, useState } from "react";
import { BaseButton } from "../../../components/Button/BaseButton.tsx";
import { Button } from "../../../components/Button/Button.tsx";
import { Dialog } from "../../../components/Dialog/Dialog.tsx";
import { useAppQuery } from "../../../http/useAppQuery.ts";
import type { Style } from "../../Homepage/types.ts";
import type { StyleCategory } from "../../types.ts";
import { StyleDescription } from "./StyleDescription.tsx";
import { StylePickerTagSection } from "./StylePickerTagSection.tsx";
import { StyleSelectionSection } from "./StyleSelectionSection.tsx";

export const StylePicker = ({
  dialogOpen,
  onDialogChange,
  onStyleSelect,
  initTag,
  initStyleUuid,
  initStyleCategory,
  selectionButtonName,
  clickOutsideAuthorized = false,
  isCancelDisabled,
}: {
  dialogOpen: boolean;
  onDialogChange: (dialogChange: boolean) => void;
  onStyleSelect?: (styleUuid: string) => void;
  initTag?: string;
  initStyleUuid?: string;
  initStyleCategory?: StyleCategory;
  clickOutsideAuthorized?: boolean;
  selectionButtonName?: string;
  isCancelDisabled?: boolean;
}) => (
  <Dialog
    className="md:w-[840px] lg:w-[1040px] h-[680px] bg-white rounded-md overflow-clip"
    isOpen={dialogOpen}
    onOpenChange={(dialogChange) => {
      onDialogChange(dialogChange);
    }}
    displayCloseButton={false}
    clickOutsideAuthorized={clickOutsideAuthorized}
    iconSize={24}
    content={
      dialogOpen && (
        <StylePickerContent
          onStyleSubmit={onStyleSelect}
          initTag={initTag}
          initStyleUuid={initStyleUuid}
          initStyleCategory={initStyleCategory}
          onStyleCancel={() => onDialogChange(false)}
          selectionButtonName={selectionButtonName}
          isCancelDisabled={isCancelDisabled}
        />
      )
    }
  />
);

const StylePickerContent = ({
  onStyleSubmit,
  onStyleCancel,
  initTag,
  initStyleUuid,
  initStyleCategory,
  selectionButtonName = "Try now",
  isCancelDisabled = false,
}: {
  onStyleSubmit?: (styleUuid: string) => void;
  onStyleCancel?: () => void;
  initTag?: string;
  initStyleUuid?: string;
  selectionButtonName?: string;
  isCancelDisabled?: boolean;
  initStyleCategory?: StyleCategory;
}) => {
  const [searchText, setSearchText] = useState("");
  const [selectedTag, setSelectedTag] = useState(
    initStyleCategory === "library" ? initTag : undefined,
  );
  const [selectedStyleUuid, setSelectedStyleUuid] = useState(initStyleUuid);
  const [styleCategory, setStyleCategory] = useState<StyleCategory>(
    initStyleCategory ?? "all",
  );

  // FIXME: We must improve the loading state of this query to avoid empty state
  const { data: styles } = useAppQuery<Style[]>({ queryKey: "styles" });

  const filteredStyles = useMemo(
    () =>
      styles?.filter((style: Style) =>
        styleCategory === "user"
          ? !style.is_curated
          : styleCategory === "library"
          ? selectedTag
            ? style.tags.includes(selectedTag)
            : style.is_curated
          : // XXX: the text search filter is only applied when "all" is selected
            style.name?.toLowerCase().includes(searchText),
      ),
    [styles, styleCategory, selectedTag, searchText],
  );

  //  XXX: If the selected style do not match the filtered styles, we reset the selected style
  useEffect(() => {
    if (
      selectedStyleUuid &&
      !filteredStyles?.map((it) => it.uuid).includes(selectedStyleUuid)
    ) {
      setSelectedStyleUuid(
        filteredStyles?.length ? filteredStyles[0].uuid : undefined,
      );
    } else if (!selectedStyleUuid && filteredStyles?.length) {
      setSelectedStyleUuid(filteredStyles[0].uuid);
    }
  }, [filteredStyles, selectedStyleUuid]);

  const selectedStyle = useMemo(
    () => styles?.find((it) => it.uuid === selectedStyleUuid),
    [styles, selectedStyleUuid],
  );

  return (
    <div className="flex-col flex-fill">
      <div className="p-lg flex-row items-center justify-between border-b">
        <div className="text-2xl font-semibold items-center">
          Select your model
        </div>

        <BaseButton
          className="h-2xl p-md aspect-square rounded-sm border hover:bg-gray-200 disabled:bg-gray-200 disabled:text-gray-350"
          onClick={onStyleCancel}
          disabled={isCancelDisabled}
        >
          Cancel
        </BaseButton>
      </div>
      {styles && (
        <div className="flex-fill flex-row">
          <StylePickerTagSection
            styles={styles}
            selectedTag={selectedTag}
            onSelectedTagChange={setSelectedTag}
            selectedCategory={styleCategory}
            onSelectedCategoryChange={setStyleCategory}
            searchText={searchText}
            onSearchTextChange={setSearchText}
          />
          <StyleSelectionSection
            styles={filteredStyles ?? []}
            onStyleUuidSelect={setSelectedStyleUuid}
            selectedStyleUuid={selectedStyleUuid}
          />
          <div className="flex-col flex-fill w-full justify-end">
            {selectedStyleUuid && (
              <StyleDescription styleUuid={selectedStyleUuid} />
            )}
            <div className="flex-row-center w-full p-md items-center justify-end border-t">
              <Button
                onClick={() => {
                  if (selectedStyleUuid) onStyleSubmit?.(selectedStyleUuid);
                }}
                disabled={selectedStyle?.status !== "ready"}
                className="w-[160px] text-xl"
              >
                {selectionButtonName}
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
