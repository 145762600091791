import classNames from "classnames";
import { useState } from "react";
import { Button } from "../../../components/Button/Button.tsx";
import { useAppMutation } from "../../../http/useAppMutation.ts";
import type { Style } from "../../types.ts";
import { ModelCreationBackButton } from "../components/ModelCreationBackButton.tsx";
import { ModelCreationCloseButton } from "../components/ModelCreationCloseButton.tsx";
import { StylePreview } from "../components/StylePreview.tsx";

export const StyleNameForm = ({
  widthClassName,
  style,
  onClickPrevious,
  onClickNext,
}: {
  widthClassName?: string;
  style: Style;
  onClickPrevious: () => void;
  onClickNext: () => void;
}) => {
  const [styleName, setStyleName] = useState<string>(style.name ?? "");

  const { mutate: updateStyleOnClickNext } = useAppMutation({
    path: "styles/update",
    invalidate: ["styles", `styles/${style.uuid}`],
    onSuccess: onClickNext,
  }).mutation;

  const { mutate: updateStyleOnClickPrevious } = useAppMutation({
    path: "styles/update",
    invalidate: ["styles", `styles/${style.uuid}`],
    onSuccess: onClickPrevious,
  }).mutation;

  return (
    <div className="relative flex-col-center w-full flex-fill overflow-y-auto">
      <ModelCreationBackButton
        onClick={() => {
          updateStyleOnClickPrevious({ uuid: style.uuid, name: null });
        }}
        className="absolute top-16 left-16"
      />
      <ModelCreationCloseButton className="absolute top-16 right-16" />
      <div
        className={classNames(
          "flex-col flex-fill pt-[80px] gap-3xl items-center",
          widthClassName,
        )}
      >
        <StylePreview style={style} title="give your model a name" />
        <input
          type="text"
          className="w-full h-3xl py-md px-lg rounded-sm border placeholder:text-gray-300 placeholder:italic"
          placeholder="My brand illustrations"
          value={styleName}
          onChange={(event) => {
            setStyleName(event.target.value);
          }}
        />
        <Button
          className="w-full"
          disabled={styleName.isEmpty()}
          onClick={() => {
            updateStyleOnClickNext({
              uuid: style.uuid,
              name: styleName,
            });
          }}
        >
          {styleName.isEmpty() ? "Name your model" : "Next"}
        </Button>
      </div>
    </div>
  );
};
