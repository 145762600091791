import { useEffect, useState } from "react";
import { BaseButton } from "../../../../../components/Button/BaseButton.tsx";
import { Icon } from "../../../../../components/Icon/Icon.tsx";
import { Image } from "../../../../../components/Image/Image.tsx";
import { Spinner } from "../../../../../components/Spinner/Spinner.tsx";
import { useAppMutation } from "../../../../../http/useAppMutation.ts";
import { useAppQuery } from "../../../../../http/useAppQuery.ts";
import { trackEvent } from "../../../../../utils/trackEvent.ts";
import { StylePicker } from "../../../../components/StylePicker/StylePicker.tsx";
import type { Style } from "../../../../types.ts";
import { TOOL_TRACKING_NAMES } from "../../../constants.ts";
import { useBoard } from "../../../hooks/useBoard.ts";
import { useSelectedTool } from "../../../hooks/useSelectedTool.ts";
import { SettingsSectionLayout } from "./SettingsSectionWrapper.tsx";

export const StyleSelectionButtonSection = () => {
  const { board } = useBoard();
  const [isStyleSelectorOpen, setIsStyleSelectorOpen] = useState(
    board.last_used_style_uuid === null,
  );

  useEffect(() => {
    if (!board.last_used_style_uuid) setIsStyleSelectorOpen(true);
  }, [board.last_used_style_uuid]);

  const { data: style, isLoading } = useAppQuery<Style>({
    queryKey: board.last_used_style_uuid
      ? `styles/${board.last_used_style_uuid}`
      : null,
  });

  const { mutate: updateBoard } = useAppMutation({
    path: "boards/update",
    invalidate: [`boards/${board.uuid}`],
  }).mutation;

  const tool = useSelectedTool();

  return (
    <>
      {/* FIXME: better manage the possible values of selectedStyleUuid. It is*/}
      {/* currently unstable. */}
      {board.last_used_style_uuid &&
        (isLoading ? (
          <Spinner />
        ) : style ? (
          <SettingsSectionLayout>
            <div className="flex-col">
              <div className="h-3xl flex-row items-center justify-between">
                <span className="font-semibold">Model</span>
              </div>
              <BaseButton
                onClick={() => {
                  setIsStyleSelectorOpen(true);
                  trackEvent(
                    "model_picker:open",
                    tool ? { generation_type: TOOL_TRACKING_NAMES[tool] } : {},
                  );
                }}
              >
                <div className="flex-row h-3xl py-md px-sm rounded-sm hover:bg-gray-200 items-center justify-between">
                  <div className="flex-row gap-md items-center w-[80%]">
                    {style.thumbnail_url ? (
                      <Image
                        src={style.thumbnail_url}
                        className="w-2xl h-2xl aspect-square rounded-xs"
                        imageClassName="h-full w-full object-cover object-center"
                      />
                    ) : (
                      <div className="w-[32px] h-[32px] flex-row-center bg-pimento-blue">
                        <Icon
                          size={16}
                          name="PimentoStar"
                          className="stroke-transparent fill-white"
                        />
                      </div>
                    )}
                    <span className="truncate w-[90%]">{style.name}</span>
                  </div>
                  <Icon name="Minus" size={18} className="stroke-xl" />
                </div>
              </BaseButton>
            </div>
          </SettingsSectionLayout>
        ) : null)}
      <StylePicker
        dialogOpen={isStyleSelectorOpen}
        onDialogChange={setIsStyleSelectorOpen}
        onStyleSelect={(styleUuid) => {
          updateBoard({
            uuid: board.uuid,
            last_used_style_uuid: styleUuid,
          });
          setIsStyleSelectorOpen(false);
        }}
        selectionButtonName="Select"
        initStyleUuid={board.last_used_style_uuid ?? undefined}
        isCancelDisabled={board.last_used_style_uuid === null}
        initStyleCategory={style && !style.is_curated ? "user" : "all"}
      />
    </>
  );
};
