import classNames from "classnames";
import { useEffect, useState } from "react";
import { Accordion } from "../../../components/Accordion/Accordion.tsx";
import { Icon } from "../../../components/Icon/Icon.tsx";
import { Image } from "../../../components/Image/Image.tsx";
import { useUser } from "../../../hooks/useUser.ts";
import { useAppMutation } from "../../../http/useAppMutation.ts";
import { useAppQuery } from "../../../http/useAppQuery.ts";
import { Info } from "../../Board/components/Info.tsx";
import {
  GENERIC_STYLE_UUID,
  STYLE_COLOR_KIND,
  STYLE_IMAGES_KIND,
} from "../../constants.ts";
import type { Style } from "../../types.ts";
import { ColorTypeDisplay, StyleTypeDisplay } from "./constants.ts";

export const StyleDescription = ({ styleUuid }: { styleUuid: string }) => {
  const { mutate: updateStyle } = useAppMutation({
    path: "styles/update",
    invalidate: [`styles/${styleUuid}`],
  }).mutation;

  const { data: style } = useAppQuery<Style>({
    queryKey: `styles/${styleUuid}`,
  });

  const { user } = useUser();

  // XXX: User can update style only if the style is its own or if the user has the permission to update curated styles
  const isStyleUpdateDisabled =
    style?.is_curated &&
    !user?.permissions.includes("styles:update-curated-styles");

  const [additionalElements, setAdditionalElements] = useState<string>(
    style?.additional_elements ?? "",
  );
  const [excludeElements, setExcludeElements] = useState<string>(
    style?.exclude_elements ?? "",
  );

  useEffect(() => {
    setAdditionalElements(style?.additional_elements ?? "");
    setExcludeElements(style?.exclude_elements ?? "");
  }, [style]);

  return (
    style && (
      <div className="flex-col flex-fill h-full pt-lg gap-lg">
        <div className="px-lg flex-row h-[64px] items-center justify-between">
          <div className="flex-row items-center gap-lg w-[80%]">
            {style.thumbnail_url ? (
              <Image
                src={style.thumbnail_url}
                className="w-[64px] h-[64px] aspect-square"
                imageClassName="h-full w-full object-cover object-center"
              />
            ) : (
              <div className="w-[64px] h-[64px] flex-row-center bg-pimento-blue">
                <Icon
                  size={32}
                  name="PimentoStar"
                  className=" stroke-transparent fill-white"
                />
              </div>
            )}
            <span
              className={classNames(
                style.name?.isBlank() && "italic",
                "text-3xl w-[90%] truncate",
              )}
            >
              {style.name ? style.name : "Untitled"}
            </span>
          </div>
          <span className="first-letter:uppercase text-lg py-sm px-md rounded-md bg-gray-200">
            {style.status === "created" ? "draft" : style.status}
          </span>
        </div>
        <div className="px-lg pb-lg flex-col flex-fill overflow-auto gap-lg">
          {style.uuid === GENERIC_STYLE_UUID && (
            <div className="p-lg bg-gray-100 rounded-sm">
              Generate any image you want in any style. Be more precise in your
              description than with other models, to get more precise results.
            </div>
          )}
          <Accordion
            accordionTriggerClassName="hover:!no-underline !py-0"
            accordionItemClassName="border-b-0 !p-0"
            iconSize={16}
            iconClassName="stroke-xl"
            elements={[
              {
                title: (
                  <div className="h-[24px] items-center font-semibold">
                    Details
                  </div>
                ),
                content: (
                  <div className="flex-col">
                    {style.type === "style" && (
                      <div className="flex-col">
                        <div className="flex-row h-[48px] w-full justify-between">
                          <div className="flex-row items-center gap-sm">
                            Type
                            <Info content="Your choice will influence your generations. “Other” will not influence your prompt." />
                          </div>
                          <select
                            className="p-sm"
                            value={style.images_kind ?? undefined}
                            onChange={(type) => {
                              updateStyle({
                                uuid: styleUuid,
                                images_kind: type.target.value,
                              });
                            }}
                            disabled={isStyleUpdateDisabled}
                          >
                            {STYLE_IMAGES_KIND.map((type, index) => (
                              <option
                                key={index}
                                value={type}
                                className="text-end"
                              >
                                {StyleTypeDisplay[type]}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="flex-row h-[48px] w-full justify-between">
                          <div className="flex-row items-center gap-sm">
                            Colors
                            <Info content="Your choice will influence your generations. “Other” will not influence your prompt." />
                          </div>
                          <select
                            className="p-sm"
                            value={style.color_kind ?? undefined}
                            onChange={(type) => {
                              updateStyle({
                                uuid: styleUuid,
                                color_kind: type.target.value,
                              });
                            }}
                            disabled={isStyleUpdateDisabled}
                          >
                            {STYLE_COLOR_KIND.map((type, index) => (
                              <option
                                key={index}
                                value={type}
                                className="text-end"
                              >
                                {ColorTypeDisplay[type]}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    )}
                    <div className="flex-col">
                      <div className="h-[48px] flex-row pt-md items-center gap-sm">
                        Style additional elements
                        <Info content="These elements will be automatically added to all your prompts." />
                      </div>
                      <textarea
                        className="w-full resize-none h-[56px] rounded-sm p-md border placeholder:text-placeholder focus:border-gray-300 hover:border-gray-300"
                        placeholder="motion blur, waterpainting..."
                        value={additionalElements}
                        onChange={(event) => {
                          setAdditionalElements(event.target.value);
                        }}
                        onKeyDown={(e) => {
                          e.stopPropagation();
                        }}
                        onBlur={() =>
                          updateStyle({
                            uuid: style.uuid,
                            additional_elements: additionalElements,
                          })
                        }
                        disabled={isStyleUpdateDisabled}
                      />
                    </div>
                    <div className="flex-col">
                      <div className="h-[48px] flex-row pt-md items-center gap-sm">
                        Exclude
                        <Info content="These elements will be automatically added to your exclude section." />
                      </div>
                      <textarea
                        className="w-full resize-none h-[56px] rounded-sm p-md border placeholder:text-placeholder focus:border-gray-300 hover:border-gray-300"
                        placeholder="very detailed, low angle, close up, indoor light, dramatic..."
                        value={excludeElements}
                        onChange={(event) => {
                          setExcludeElements(event.target.value);
                        }}
                        onKeyDown={(e) => {
                          e.stopPropagation();
                        }}
                        onBlur={() =>
                          updateStyle({
                            uuid: style.uuid,
                            exclude_elements: excludeElements,
                          })
                        }
                        disabled={isStyleUpdateDisabled}
                      />
                    </div>
                  </div>
                ),
                value: "Details",
              },
            ]}
          />
          <div className="flex-col gap-md">
            <span className="h-lg font-semibold">Initial images</span>
            <div className="grid grid-cols-4 gap-sm">
              {style.uuid === GENERIC_STYLE_UUID
                ? GENERIC_FAKE_IMAGES.map((url) => (
                    <div key={url} className="h-full w-full aspect-square">
                      <Image
                        className="h-full w-full aspect-square"
                        imageClassName="h-full w-full object-cover object-center rounded-xs"
                        src={url}
                      />
                    </div>
                  ))
                : style.training_images.map((image) => (
                    <div
                      key={image.uuid}
                      className="h-full w-full aspect-square"
                    >
                      <Image
                        className="h-full w-full aspect-square"
                        imageClassName="h-full w-full object-cover object-center rounded-xs"
                        src={image.url}
                      />
                    </div>
                  ))}
            </div>
          </div>
        </div>
      </div>
    )
  );
};

const GENERIC_FAKE_IMAGES = [
  "https://storage.googleapis.com/419c45cf-be8a-4cba-bbcd-74a221eb2587/app/assets/generic/generic_1.jpg",
  "https://storage.googleapis.com/419c45cf-be8a-4cba-bbcd-74a221eb2587/app/assets/generic/generic_2.jpg",
  "https://storage.googleapis.com/419c45cf-be8a-4cba-bbcd-74a221eb2587/app/assets/generic/generic_3.jpg",
  "https://storage.googleapis.com/419c45cf-be8a-4cba-bbcd-74a221eb2587/app/assets/generic/generic_4.jpg",
  "https://storage.googleapis.com/419c45cf-be8a-4cba-bbcd-74a221eb2587/app/assets/generic/generic_5.jpg",
  "https://storage.googleapis.com/419c45cf-be8a-4cba-bbcd-74a221eb2587/app/assets/generic/generic_6.jpg",
  "https://storage.googleapis.com/419c45cf-be8a-4cba-bbcd-74a221eb2587/app/assets/generic/generic_7.jpg",
  "https://storage.googleapis.com/419c45cf-be8a-4cba-bbcd-74a221eb2587/app/assets/generic/generic_8.jpg",
  "https://storage.googleapis.com/419c45cf-be8a-4cba-bbcd-74a221eb2587/app/assets/generic/generic_9.jpg",
  "https://storage.googleapis.com/419c45cf-be8a-4cba-bbcd-74a221eb2587/app/assets/generic/generic_10.jpg",
  "https://storage.googleapis.com/419c45cf-be8a-4cba-bbcd-74a221eb2587/app/assets/generic/generic_11.jpg",
  "https://storage.googleapis.com/419c45cf-be8a-4cba-bbcd-74a221eb2587/app/assets/generic/generic_12.jpg",
  "https://storage.googleapis.com/419c45cf-be8a-4cba-bbcd-74a221eb2587/app/assets/generic/generic_13.jpg",
  "https://storage.googleapis.com/419c45cf-be8a-4cba-bbcd-74a221eb2587/app/assets/generic/generic_14.jpg",
  "https://storage.googleapis.com/419c45cf-be8a-4cba-bbcd-74a221eb2587/app/assets/generic/generic_15.jpg",
] as const;
