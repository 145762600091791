export const ASSETS = "/assets";
export const HOME = "/";
export const ADMIN = "/admin";

export const BOARDS = "/boards";
export const STYLES = "/styles";

// Board - Workspaces
export const CREATE_WORKSPACE = "create";
export const EDIT_WORKSPACE = "edit";

export const ASSETS_WORKSPACE = "assets";

// Board - Tools
export const GENERATE_TOOL = "generate";
export const GENERATE_TOOL_PATH =
  `${CREATE_WORKSPACE}/${GENERATE_TOOL}` as const;
export const TRANSFER_TOOL = "transfer";
export const TRANSFER_TOOL_PATH =
  `${CREATE_WORKSPACE}/${TRANSFER_TOOL}` as const;

export const VARIATION_TOOL = "variation";
export const VARIATION_TOOL_PATH =
  `${EDIT_WORKSPACE}/${VARIATION_TOOL}` as const;
export const DRAW_TOOL = "draw";
export const DRAW_TOOL_PATH = `${EDIT_WORKSPACE}/${DRAW_TOOL}` as const;
export const SELECT_TOOL = "select";
export const SELECT_TOOL_PATH = `${EDIT_WORKSPACE}/${SELECT_TOOL}` as const;
export const UPSCALE_TOOL = "upscale";
export const UPSCALE_TOOL_PATH = `${EDIT_WORKSPACE}/${UPSCALE_TOOL}` as const;
