import { ImagePicker } from "../../../../components/ImagePicker.tsx";
import { ErrorMessage } from "../../../components/ErrorMessage.tsx";
import { SettingsSectionWrapper } from "../../../components/SettingsMenu/SettingsSectionWrapper.tsx";

export const InitialImageWrappedSection = ({
  imageUuid,
  onImageUuidChange,
  error,
}: {
  imageUuid?: string;
  onImageUuidChange: (uuid: string | undefined) => void;
  error?: string;
}) => (
  <SettingsSectionWrapper
    name="Input image"
    content={
      <div className="flex-col gap-md">
        <ImagePicker
          imageUuid={imageUuid}
          onImageUuidChange={onImageUuidChange}
          isError={!!error}
        />
        {error && <ErrorMessage text={error} />}
      </div>
    }
  />
);
