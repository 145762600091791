import classNames from "classnames";
import { useState } from "react";
import { Button } from "../../../components/Button/Button.tsx";
import { useAppMutation } from "../../../http/useAppMutation.ts";
import type { Style } from "../../types.ts";
import { ModelCreationBackButton } from "../components/ModelCreationBackButton.tsx";
import { ModelCreationCloseButton } from "../components/ModelCreationCloseButton.tsx";
import { StylePreview } from "../components/StylePreview.tsx";

export const ObjectAndCharacterDescriptionForm = ({
  style,
  styleType,
  onClickNext,
  onClickPrevious,
  widthClassName,
}: {
  style: Style;
  styleType: "object" | "character";
  onClickNext: () => void;
  onClickPrevious: () => void;
  widthClassName?: string;
}) => {
  const [subjectDescription, setSubjectDescription] = useState<string>("");

  const updateStyleOnClickPrevious = useAppMutation({
    path: "styles/update",
    invalidate: [`styles/${style.uuid}`],
    onSuccess: () => onClickPrevious(),
  }).mutation;

  const updateStyleOnClickNext = useAppMutation({
    path: "styles/update",
    invalidate: [`styles/${style.uuid}`],
    onSuccess: () => onClickNext(),
  }).mutation;

  return (
    <div className="relative flex-col-center w-full flex-fill overflow-y-auto">
      <ModelCreationBackButton
        onClick={() =>
          updateStyleOnClickPrevious.mutate({
            uuid: style.uuid,
            subject_description: null,
          })
        }
        className="absolute top-16 left-16"
      />
      <ModelCreationCloseButton className="absolute top-16 right-16" />
      <div
        className={classNames(
          "flex-col flex-fill pt-[80px] gap-3xl items-center",
          widthClassName,
        )}
      >
        <StylePreview style={style} title={`describe your ${styleType}`} />
        <div className="w-full flex-col gap-md">
          <div className="font-semibold">
            Be precise and use less than 5 words
          </div>
          <input
            type="text"
            className="w-full h-3xl py-md px-lg rounded-sm border placeholder:text-gray-300 placeholder:italic"
            placeholder={
              style.type === "object"
                ? "Minimalist black rectangular bag"
                : "Young blonde woman"
            }
            value={subjectDescription}
            onChange={(event) => {
              setSubjectDescription(event.target.value);
            }}
          />
          <div>
            <div className="text-gray-400 italic">Examples:</div>
            <ul className="pl-lg list-disc italic text-gray-400">
              {(style.type === "character"
                ? CHARACTER_EXAMPLES
                : OBJECT_EXAMPLES
              ).map((example) => (
                <li key={example} className="text-inherit">
                  {example}
                </li>
              ))}
            </ul>
          </div>
        </div>
        <Button
          className="w-full"
          disabled={subjectDescription.isEmpty()}
          onClick={() => {
            updateStyleOnClickNext.mutate({
              uuid: style.uuid,
              subject_description: subjectDescription,
            });
          }}
        >
          {subjectDescription.isEmpty()
            ? `Describe your ${styleType}`
            : "Create my model"}
        </Button>
      </div>
    </div>
  );
};

const OBJECT_EXAMPLES = [
  "minimalist black rectangular bag",
  "cushion-cut canary yellow ring diamond",
  "sleek black stylish tech-enhanced sunglasses",
] as const;

const CHARACTER_EXAMPLES = [
  "young and tall blond woman",
  "cute pink panther stuffed toy",
  "55 year-old black man",
] as const;
