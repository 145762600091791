import { Button } from "../../../../../../components/Button/Button.tsx";
import { Dialog } from "../../../../../../components/Dialog/Dialog.tsx";
import { Icon } from "../../../../../../components/Icon/Icon.tsx";
import { Image } from "../../../../../../components/Image/Image.tsx";

export const StyleTransferBetaDialog = ({
  isOpen,
  onOpenChange,
}: {
  isOpen: boolean;
  onOpenChange: (open: boolean) => void;
}) => (
  <Dialog
    className="rounded-md overflow-hidden"
    isOpen={isOpen}
    onOpenChange={onOpenChange}
    displayCloseButton={false}
    content={
      <div className="flex-col w-[360px] items-center pb-lg bg-white">
        <div className="h-[218px] w-full flex-row-center p-xl gap-lg bg-gray-100">
          <Image
            className="h-[120px] aspect-square rounded-md border bg-white"
            imageClassName="h-full w-full object-contain object-center"
            src="https://storage.googleapis.com/419c45cf-be8a-4cba-bbcd-74a221eb2587/app/assets/navbar/tools/transfer_1_transparency.png"
          />
          <Icon name="ArrowRight" size={24} className="stroke-gray-350" />
          <Image
            className="h-[120px] aspect-square rounded-md border bg-white"
            imageClassName="h-full w-full object-contain object-center"
            src="https://storage.googleapis.com/419c45cf-be8a-4cba-bbcd-74a221eb2587/app/assets/navbar/tools/transfer_2_transparency.png"
          />
        </div>
        <div className="flex-col h-[182px] items-center gap-md px-xl py-lg justify-between">
          <div className="flex-col items-center gap-md">
            <div className="h-xl text-xl font-semibold">
              Transfer is in beta
            </div>
            <div className="h-2xl text-lg text-gray-350 text-center leading-5">
              Transfer is currently in beta, quality of outputs may vary.
            </div>
          </div>
          <Button className="w-full" onClick={() => onOpenChange(false)}>
            I understand and want to test
          </Button>
        </div>
      </div>
    }
  />
);
