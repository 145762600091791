import { useQueryClient } from "@tanstack/react-query";
import classNames from "classnames";
import { useState } from "react";
import { BaseButton } from "../../../../../../components/Button/BaseButton.tsx";
import { Button } from "../../../../../../components/Button/Button.tsx";
import { Dialog } from "../../../../../../components/Dialog/Dialog.tsx";
import { useAppMutation } from "../../../../../../http/useAppMutation.ts";
import { useBoard } from "../../../../hooks/useBoard.ts";
import { useSelectedAsset } from "../../../../hooks/useSelectedAsset.ts";
import { ImageThumbnail } from "../ImageThumbnail/ImageThumbnail.tsx";

export const GenerationResultOptions = () => {
  const { board } = useBoard();
  const { selectedAsset } = useSelectedAsset();

  const { mutate: updateAssetContent, isLoading: updateAssetContentIsLoading } =
    useAppMutation({
      path: "assets/update",
      invalidate: [`boards/${board.uuid}`],
    }).mutation;

  const queryClient = useQueryClient();

  if (!selectedAsset || selectedAsset.ancestors.isEmpty()) return null;
  return (
    <div className="h-full w-[120px] flex-col justify-end gap-lg">
      <div className="shrink flex-col gap-lg overflow-y-auto">
        {[...selectedAsset.siblings]
          .sortAsc((it) => it.created_at)
          .map((image) => (
            <ImageThumbnail
              key={image.uuid}
              image={image}
              fit="width"
              onClick={() =>
                updateAssetContent(
                  {
                    uuid: selectedAsset.uuid,
                    content_uuid: image.uuid,
                  },
                  {
                    onSuccess: () => {
                      void queryClient.invalidateQueries({
                        queryKey: [`assets/${selectedAsset.uuid}`],
                      });
                    },
                  },
                )
              }
              isDisabled={updateAssetContentIsLoading}
              isSelected={selectedAsset.content.uuid === image.uuid}
            />
          ))}
      </div>
      {selectedAsset.ancestors.at(-1) && (
        <DiscardAllButton
          onClick={() =>
            updateAssetContent(
              {
                uuid: selectedAsset.uuid,
                content_uuid: selectedAsset.ancestors.at(-1)!.uuid,
              },
              {
                onSuccess: () => {
                  void queryClient.invalidateQueries({
                    queryKey: [`assets/${selectedAsset.uuid}`],
                  });
                },
              },
            )
          }
          isDisabled={updateAssetContentIsLoading}
        />
      )}
    </div>
  );
};

const DiscardAllButton = ({
  onClick,
  isDisabled,
}: {
  onClick: () => void;
  isDisabled: boolean;
}) => {
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);

  return (
    <>
      <Button
        onClick={() => setConfirmationDialogOpen(true)}
        disabled={isDisabled}
      >
        Discard All
      </Button>
      <ParentRollbackDialog
        dialogOpen={confirmationDialogOpen}
        onDialogChange={setConfirmationDialogOpen}
        onClick={onClick}
      />
    </>
  );
};

const ParentRollbackDialog = ({
  dialogOpen,
  onDialogChange,
  onClick,
}: {
  dialogOpen: boolean;
  onDialogChange: (dialogChange: boolean) => void;
  onClick: () => void;
}) => (
  <Dialog
    isOpen={dialogOpen}
    onOpenChange={onDialogChange}
    displayCloseButton={false}
    clickOutsideAuthorized
    className="sm:max-w-[500px] w-[400px] bg-white p-2xl"
    content={
      <>
        <span className="text-2xl font-bold">Discard generated images?</span>
        <div className="mt-md mb-3xl text-base">
          Your images will be deleted forever.
        </div>
        <div className="flex-row justify-between">
          <BaseButton
            onClick={() => onDialogChange(false)}
            className="rounded-sm py-sm flex-row items-center"
          >
            <div className="text-black font-medium underline underline-offset-4 hover:text-gray-700">
              Cancel
            </div>
          </BaseButton>
          <BaseButton
            className={classNames(
              "rounded-sm border-2 min-w-[90px] min-h-[40px] flex-row-center hover:bg-gray-200",
            )}
            onClick={() => {
              onClick();
              onDialogChange(false);
            }}
          >
            <div className="text-black font-medium">Discard</div>
          </BaseButton>
        </div>
      </>
    }
  />
);
