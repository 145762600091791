import classNames from "classnames";
import { type ReactNode, useState } from "react";
import TextareaAutosize from "react-textarea-autosize";
import { Button } from "../../../../../components/Button/Button.tsx";
import { ClickableIcon } from "../../../../../components/Icon/ClickableIcon.tsx";
import { Image } from "../../../../../components/Image/Image.tsx";
import { Spinner } from "../../../../../components/Spinner/Spinner.tsx";
import type { Style } from "../../../../types.ts";
import { ErrorMessage } from "../ErrorMessage.tsx";
import { SettingsSectionWrapper } from "../SettingsMenu/SettingsSectionWrapper.tsx";
import { PromptEnhancementButton } from "./PromptEnhancer/PromptEnhancementButton.tsx";

export const GenerationBar = ({
  prompt,
  setPrompt,
  promptError,
  isLoading,
  isDeactivated,
  onGenerate,
  infoContent,
  extraErrors,
  isPromptLoading,
  onPromptLoadingChange,
  style,
  extraCaption,
  loadingPromptContent = "Describing image…",
  sectionTitle = "Prompt",
  buttonContent = "Generate",
  placeholder = "An illustration of wonderful chilli pepper basking in the sun",
  blockEmptyPrompt = false,
  autoFocus = false,
  showEnhancer = false,
}: {
  prompt: string;
  promptError?: string;
  setPrompt: (prompt: string) => void;
  isLoading: boolean;
  isPromptLoading?: boolean;
  onPromptLoadingChange?: (loading: boolean) => void;
  loadingPromptContent?: ReactNode;
  onGenerate: () => void;
  style?: Style;
  infoContent?: ReactNode;
  extraErrors?: string[];
  sectionTitle?: string;
  buttonContent?: string;
  isDeactivated?: boolean;
  placeholder?: string;
  blockEmptyPrompt?: boolean;
  autoFocus?: boolean;
  showEnhancer?: boolean;
  extraCaption?: ReactNode;
}) => {
  const [isEnhancementLoading, setIsEnhancementLoading] = useState(false);

  const generationDeactivated =
    (blockEmptyPrompt && !prompt) || isLoading || isDeactivated;
  return (
    <SettingsSectionWrapper
      name={sectionTitle}
      infoContent={infoContent}
      className="border-t border-b-0 pb-lg"
      content={
        <div className="w-full flex-col-center gap-md">
          <div className="w-full flex-col gap-md">
            <div className="relative w-full flex-col-center">
              <TextareaAutosize
                placeholder={placeholder}
                minRows={2}
                maxRows={12}
                className={classNames(
                  "w-full flex-fill px-sm py-md resize-none border rounded-sm",
                  promptError ? "border-error-red-200" : "",
                  isEnhancementLoading ? "animate-pulse" : "",
                )}
                value={prompt}
                onChange={(event) => {
                  setPrompt(event.target.value);
                }}
                autoFocus={autoFocus}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    if (!generationDeactivated) onGenerate();
                  }
                  e.stopPropagation();
                }}
                disabled={isEnhancementLoading}
              />
              {isPromptLoading && onPromptLoadingChange && (
                <>
                  <div className="w-full h-full absolute left-0 rounded-sm bg-gray-100 flex-row-center gap-sm">
                    <Spinner small inline currentColor />
                    {loadingPromptContent}
                  </div>
                  <ClickableIcon
                    name="X"
                    className="absolute top-0 right-0"
                    onClick={() => onPromptLoadingChange(false)}
                  />
                </>
              )}
            </div>
            {promptError && <ErrorMessage text={promptError} />}
            <div className="flex-row items-center gap-sm">
              <Image
                className="h-[13px] aspect-square rounded"
                imageClassName="h-full w-full object-contain object-center"
                src="https://storage.googleapis.com/419c45cf-be8a-4cba-bbcd-74a221eb2587/app/assets/flag/us.png"
              />
              <div className="text-gray-500">
                English prompts improve results.
              </div>
            </div>
          </div>
          <div className="w-full flex-col gap-md">
            {showEnhancer && (
              <PromptEnhancementButton
                prompt={prompt}
                onPromptChange={setPrompt}
                onLoadingChange={setIsEnhancementLoading}
                style={style}
              />
            )}
            <Button
              className="w-full"
              disabled={generationDeactivated}
              onClick={() => {
                onGenerate();
              }}
              loading={isLoading}
            >
              {buttonContent}
            </Button>
            {extraCaption}
            {extraErrors?.map((error) => (
              <ErrorMessage key={error} text={error} />
            ))}
          </div>
        </div>
      }
    />
  );
};
