import type { AxiosResponse } from "axios";
import { useAppMutation } from "../../../../../../../http/useAppMutation.ts";
import { useBoard } from "../../../../../hooks/useBoard.ts";
import { useStyleTransferSettings } from "./useStyleTransferSettings.ts";

export const useStyleTransferGeneration = ({
  onSuccess,
}: {
  onSuccess?: (
    response: AxiosResponse<{
      image_uuids: string[];
    }>,
  ) => void;
}) => {
  const { board } = useBoard();
  const { styleTransferSettings } = useStyleTransferSettings();

  const transferStyleMutation = useAppMutation<{
    image_uuids: string[];
  }>({
    path: "boards/transfer-style",
    invalidate: [`boards/${board.uuid}`, "boards", "users/me"],
    onSuccess,
  }).mutation;

  return {
    mutation: ({
      prompt,
      initImageUuid,
      styleUuid,
    }: {
      prompt: string;
      initImageUuid: string;
      styleUuid: string;
    }) => {
      transferStyleMutation.mutate({
        board_uuid: board.uuid,
        prompt,
        style_uuid: styleUuid,
        prompt_strength:
          styleTransferSettings.transfer_strength_preset === "custom"
            ? styleTransferSettings.prompt_strength
            : undefined,
        lora_scale:
          styleTransferSettings.transfer_strength_preset === "custom"
            ? styleTransferSettings.lora_scale
            : undefined,
        init_image_uuid: initImageUuid,
        controlnet_1_image_uuid: initImageUuid,
        controlnet_1_conditioning_scale:
          styleTransferSettings.transfer_strength_preset === "custom"
            ? styleTransferSettings.controlnet_1_conditioning_scale
            : undefined,
        controlnet_1_end:
          styleTransferSettings.transfer_strength_preset === "custom"
            ? styleTransferSettings.controlnet_1_end
            : undefined,
        negative_prompt: styleTransferSettings.negative_prompt,
        transfer_strength_preset:
          styleTransferSettings.transfer_strength_preset,
        init_image_color_influence:
          styleTransferSettings.init_image_color_influence,
        quality_preset: styleTransferSettings.quality_preset,
      });
    },
    isLoading: transferStyleMutation.isLoading,
  };
};
