import classNames from "classnames";
import {
  AppWindow,
  ArrowBigUp,
  ArrowDownToLine,
  ArrowLeft,
  ArrowRight,
  ArrowRightToLine,
  ArrowUpRight,
  ArrowUpRightSquare,
  ArrowUpToLine,
  Brush,
  Check,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  ChevronsLeft,
  ChevronsRight,
  Circle,
  ClipboardPaste,
  ClipboardType,
  Command,
  Compass,
  Copy,
  CopyPlus,
  CreditCard,
  Dices,
  Dot,
  Ellipsis,
  Eraser,
  Expand,
  FilePlus,
  Flame,
  FlaskConical,
  Hash,
  Heart,
  HelpCircle,
  Home,
  Image,
  ImagePlus,
  Info,
  Lasso,
  Layers,
  Library,
  LifeBuoy,
  Lightbulb,
  Link,
  Lock,
  LogOut,
  Maximize,
  Menu,
  Minus,
  MoreHorizontal,
  MousePointer,
  MousePointer2,
  MoveLeft,
  Paintbrush2 as Paintbrush,
  Palette,
  Pen,
  Pencil,
  Pin,
  Pipette,
  PlayCircle,
  Plus,
  PlusCircle,
  Redo,
  Save,
  ScissorsSquare,
  Search,
  Settings2 as Settings,
  SlidersHorizontal,
  Sparkles,
  SquareArrowOutUpRight,
  StopCircle,
  Tag,
  Target,
  Text,
  ThumbsDown,
  ThumbsUp,
  Trash,
  Triangle,
  TriangleAlert,
  Type,
  Undo,
  UploadCloud,
  UserRound,
  WandSparkles,
  X,
} from "lucide-react";
import type { SVGProps } from "react";
import { Tooltip, type TooltipProps } from "../Tooltip/Tooltip.tsx";
import { Assets } from "./assets/Assets.tsx";
import { Bolt } from "./assets/Bolt.tsx";
import { Discord } from "./assets/Discord.tsx";
import { Download } from "./assets/Download.tsx";
import { Draw } from "./assets/Draw.tsx";
import { ImageSlider } from "./assets/ImageSlider.tsx";
import { ImageStripe } from "./assets/ImageStripe.tsx";
import { Invalid } from "./assets/Invalid.tsx";
import { OnboardingCenteredTip } from "./assets/OnboardingCenteredTip.tsx";
import { OnboardingCharacterAngle1 } from "./assets/OnboardingCharacterAngle1.tsx";
import { OnboardingCharacterAngle2 } from "./assets/OnboardingCharacterAngle2.tsx";
import { OnboardingHorizontalFormat } from "./assets/OnboardingHorizontalFormat.tsx";
import { OnboardingNonCenteredTip } from "./assets/OnboardingNonCenteredTip.tsx";
import { OnboardingNoTextTip } from "./assets/OnboardingNoTextTip.tsx";
import { OnboardingObjectAngle1 } from "./assets/OnboardingObjectAngle1.tsx";
import { OnboardingObjectAngle2 } from "./assets/OnboardingObjectAngle2.tsx";
import { OnboardingSizeTip } from "./assets/OnboardingSizeTip.tsx";
import { OnboardingSquareFormat } from "./assets/OnboardingSquareFormat.tsx";
import { OnboardingVerticalFormat } from "./assets/OnboardingVerticalFormat.tsx";
import { Pimento } from "./assets/Pimento.tsx";
import { PimentoHome } from "./assets/PimentoHome.tsx";
import { PimentoStar } from "./assets/PimentoStar.tsx";
import { RectangleHorizontal } from "./assets/RectangleHorizontal.tsx";
import { RectangleVertical } from "./assets/RectangleVertical.tsx";
import { RemoveBackground } from "./assets/RemoveBackground.tsx";
import { Select } from "./assets/Select.tsx";
import { Sparks } from "./assets/Sparks.tsx";
import { Square } from "./assets/Square.tsx";
import { StyleTransfer } from "./assets/StyleTransfer.tsx";
import { Upscale } from "./assets/Upscale.tsx";
import { Valid } from "./assets/Valid.tsx";
// https://lucide.dev/icons/
const IconsMap = {
  AppWindow,
  ArrowBigUp,
  ArrowDownToLine,
  ArrowLeft,
  ArrowRight,
  ArrowUpRight,
  ArrowUpRightSquare,
  ArrowUpToLine,
  ArrowRightToLine,
  Assets,
  Bolt,
  Brush,
  Check,
  ChevronDown,
  ChevronLeft,
  ChevronsLeft,
  ChevronRight,
  ChevronsRight,
  ClipboardPaste,
  ClipboardType,
  Circle,
  Command,
  Compass,
  Copy,
  CopyPlus,
  CreditCard,
  Dices,
  Discord,
  Dot,
  Draw,
  Download,
  Ellipsis,
  Eraser,
  Expand,
  FilePlus,
  Flame,
  FlaskConical,
  Hash,
  Heart,
  HelpCircle,
  Home,
  Pimento,
  PimentoHome,
  PimentoStar,
  Image,
  ImageSlider,
  ImagePlus,
  Info,
  Invalid,
  Lasso,
  Layers,
  Lock,
  LogOut,
  Library,
  Lightbulb,
  Link,
  Maximize,
  Menu,
  Minus,
  MoreHorizontal,
  MousePointer,
  MousePointer2,
  MoveLeft,
  OnboardingCenteredTip,
  OnboardingCharacterAngle1,
  OnboardingCharacterAngle2,
  OnboardingHorizontalFormat,
  OnboardingNonCenteredTip,
  OnboardingNoTextTip,
  OnboardingObjectAngle1,
  OnboardingObjectAngle2,
  OnboardingSizeTip,
  OnboardingSquareFormat,
  OnboardingVerticalFormat,
  LifeBuoy,
  Paintbrush,
  Palette,
  Pin,
  Pipette,
  Plus,
  Pen,
  Pencil,
  PlayCircle,
  PlusCircle,
  RectangleHorizontal,
  RectangleVertical,
  Redo,
  RemoveBackground,
  Save,
  ScissorsSquare,
  Search,
  Select,
  Settings,
  SlidersHorizontal,
  Square,
  SquareArrowOutUpRight,
  StopCircle,
  Sparks,
  Sparkles,
  ImageStripe,
  StyleTransfer,
  Tag,
  Target,
  Text,
  ThumbsDown,
  ThumbsUp,
  Trash,
  Triangle,
  TriangleAlert,
  Type,
  Undo,
  UploadCloud,
  Upscale,
  UserRound,
  Valid,
  WandSparkles,
  X,
};
export type IconName = keyof typeof IconsMap;
export type IconProps = {
  name: IconName;
  size?: number;
  tooltip?: Omit<TooltipProps, "children">;
  rotate?: number;
} & Omit<SVGProps<SVGSVGElement>, "height" | "width" | "ref">;

export const Icon = ({
  name,
  // FIXME: Use preset of size (sm, md, lg) instead of size
  size,
  tooltip,
  rotate,
  style,
  ...rest
}: IconProps) => {
  const Comp = IconsMap[name];
  const iconProps = {
    style: {
      transform: rotate ? `rotate(${rotate}deg)` : undefined,
      ...style,
    },
    size: size ?? 16,
    ...rest,
  };
  if (tooltip) {
    return (
      <Tooltip
        {...{
          ...tooltip,
          className: classNames("max-w-[250px]", tooltip.className),
        }}
      >
        <Comp {...iconProps} />
      </Tooltip>
    );
  }
  return <Comp {...iconProps} />;
};
