import classNames from "classnames";
import type { ReactNode } from "react";
import { useDropzone } from "react-dropzone";
import Resizer from "react-image-file-resizer";
import { loadImage } from "../../../../utils/image.ts";
import { notifier } from "../../../../utils/notifier.ts";
import { pseudoRandomUUID } from "../../../../utils/stackoverflow.ts";

export const ImageDropZone = ({
  onDrop,
  content,
  className,
  minImageSideDimension = 256,
  maxSizeBeforeResize = 1024,
}: {
  onDrop: (file: { fileToUpload: File; uuid: string } | undefined) => void;
  content: ReactNode;
  className?: string;
  minImageSideDimension?: number;
  maxSizeBeforeResize?: number;
}) => {
  const {
    getRootProps,
    getInputProps,
    open: openFileBrowser,
  } = useDropzone({
    multiple: false,
    noClick: true,
    accept: {
      "image/png": [".png"],
      "image/jpeg": [".jpeg", ".jpg"],
    },
    onDrop: (acceptedFile) => {
      void loadImage(URL.createObjectURL(acceptedFile[0])).then((image) => {
        if (
          image.width < minImageSideDimension ||
          image.height < minImageSideDimension
        ) {
          notifier.error(
            `Your image dimensions should be greater than ${minImageSideDimension}x${minImageSideDimension} pixels`,
          );
        } else {
          Resizer.imageFileResizer(
            acceptedFile[0],
            maxSizeBeforeResize,
            maxSizeBeforeResize,
            "JPEG",
            100,
            0,
            (file) => {
              onDrop({
                uuid: pseudoRandomUUID(),
                fileToUpload: file as File,
              });
            },
            "blob",
          );
        }
      });
    },
  });

  return (
    <div {...getRootProps()} className="h-full w-full">
      <input {...getInputProps()} />
      <button
        type="button"
        className={classNames(
          "h-full w-full border border-dashed border-pimento-blue bg-pimento-blue bg-opacity-5",
          className,
        )}
        onClick={() => openFileBrowser()}
      >
        <div className="flex-col-center gap-3xl max-h-[250px]">
          <input {...getInputProps()} />
          {content}
        </div>
      </button>
    </div>
  );
};
