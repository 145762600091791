import { useAppMutation } from "../../../http/useAppMutation.ts";

export const useUploadImage = ({
  onSuccess,
  describeImage = false,
}: {
  onSuccess?: (imageUuid: string) => void;
  describeImage?: boolean;
}) => {
  const {
    mutation: { mutate: upload, isLoading },
    progress,
  } = useAppMutation({
    path: "contents/upload",
    invalidate: [],
    onSuccess: (response: { data: { image_uuid: string } }) =>
      onSuccess?.(response.data.image_uuid),
    config: {
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
    },
  });

  return {
    mutate: ({ image }: { image: Blob }) => {
      const formData = new FormData();
      formData.append("file", image);
      if (describeImage) formData.append("describe", "true");
      upload(formData);
    },
    isLoading,
    progress,
  };
};
