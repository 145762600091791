import { useEffect, useState } from "react";
import { useAppMutation } from "../../http/useAppMutation.ts";
import type { Style } from "../types.ts";
import { FORM_INPUT_WIDTH_CSS } from "./constants.ts";
import { ObjectAndCharacterDescriptionForm } from "./CreationPage/ObjectAndCharacterDescriptionForm.tsx";
import { StyleInitSplashScreen } from "./CreationPage/StyleInitSplashScreen.tsx";
import { StyleNameForm } from "./CreationPage/StyleNameForm.tsx";
import { TrainingImagesForm } from "./CreationPage/TrainingImagesForm.tsx";
import { StyleDescription } from "./StyleDescription.tsx";
import type { ObjectAndCharacterCreationStep } from "./types.ts";

export const ObjectAndCharacterModelCreation = ({
  style,
  styleType,
}: {
  style: Style;
  styleType: "object" | "character";
}) => {
  const [currentModelCreationStep, setCurrentModelCreationStep] =
    useState<ObjectAndCharacterCreationStep>("modelTrainingImagesForm");

  const trainStyle = useAppMutation({
    path: "styles/train",
    invalidate: [`styles/${style.uuid}`],
    onSuccess: () => setCurrentModelCreationStep("modelInitSplashScreen"),
  }).mutation;

  const CONCEPT_TOKEN = "TOK";

  useEffect(() => {
    //  XXX: If we start again the onboarding we set the step to the latest step that was reached
    if (style.status === "ready") {
      setCurrentModelCreationStep("modelDescription");
    } else if (style.status === "training") {
      setCurrentModelCreationStep("modelInitSplashScreen");
    } else if (style.status === "created") {
      if (
        style.subject_description?.length === 0 ||
        style.subject_description === null
      ) {
        setCurrentModelCreationStep("modelTrainingImagesForm");
      } else if (style.name === null) {
        setCurrentModelCreationStep("modelNameForm");
      } else {
        setCurrentModelCreationStep("modelNameForm");
      }
    }
  }, [style]);

  // FIXME: align step names and component names
  switch (currentModelCreationStep) {
    case "modelTrainingImagesForm":
      return (
        <TrainingImagesForm
          style={style}
          widthClassName={FORM_INPUT_WIDTH_CSS}
          onClickPrevious={() => {
            setCurrentModelCreationStep("modelSubjectDescriptionForm");
          }}
        />
      );
    case "modelSubjectDescriptionForm":
      return (
        <ObjectAndCharacterDescriptionForm
          style={style}
          styleType={styleType}
          widthClassName={FORM_INPUT_WIDTH_CSS}
          onClickNext={() => {
            setCurrentModelCreationStep("modelNameForm");
          }}
          onClickPrevious={() => {
            setCurrentModelCreationStep("modelTrainingImagesForm");
          }}
        />
      );
    case "modelNameForm":
      return (
        <StyleNameForm
          widthClassName={FORM_INPUT_WIDTH_CSS}
          style={style}
          onClickPrevious={() =>
            setCurrentModelCreationStep("modelSubjectDescriptionForm")
          }
          onClickNext={() =>
            trainStyle.mutate({
              uuid: style.uuid,
              caption_prefix: `${CONCEPT_TOKEN} ${
                style.subject_description ?? ""
              }, `,
              concept_token: CONCEPT_TOKEN,
            })
          }
        />
      );
    case "modelInitSplashScreen":
      return <StyleInitSplashScreen widthClassName={FORM_INPUT_WIDTH_CSS} />;
    case "modelDescription":
      return <StyleDescription style={style} />;
  }
};
