import * as PopoverPrimitive from "@radix-ui/react-popover";
import classNames from "classnames";
import type { ReactNode } from "react";
import { Icon } from "../Icon/Icon.tsx";

export const Popover = ({
  children,
  content,
  isOpen,
  onOpenChange,
  className,
  arrowClassName,
  arrowDisplayed,
  side = "top",
  align = "center",
  modal = false,
  showCloseButton = false,
  ...props
}: {
  children: ReactNode;
  content?: ReactNode;
  isOpen?: boolean;
  onOpenChange?: (open: boolean) => void;
  className?: string;
  arrowClassName?: string;
  arrowDisplayed?: boolean;
  showCloseButton?: boolean;
  side?: PopoverPrimitive.PopoverContentProps["side"];
  align?: PopoverPrimitive.PopoverContentProps["align"];
  modal?: boolean;
} & Omit<PopoverPrimitive.PopoverContentProps, "content">) => {
  if (!content) return children;

  return (
    <PopoverPrimitive.Root
      open={isOpen}
      onOpenChange={onOpenChange}
      modal={modal}
    >
      <PopoverPrimitive.Trigger asChild>{children}</PopoverPrimitive.Trigger>
      <PopoverPrimitive.Portal>
        <PopoverPrimitive.Content
          align={align}
          side={side}
          onOpenAutoFocus={(e) => e.preventDefault()}
          className={classNames(
            "z-10 w-72 p-lg bg-white rounded-sm shadow-md outline-none data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2",
            className,
          )}
          {...props}
        >
          {showCloseButton && (
            <PopoverPrimitive.Close className="absolute right-8 top-8 rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-80">
              <Icon name="X" />
            </PopoverPrimitive.Close>
          )}
          {content}
          {arrowDisplayed && (
            <PopoverPrimitive.Arrow className={arrowClassName} />
          )}
        </PopoverPrimitive.Content>
      </PopoverPrimitive.Portal>
    </PopoverPrimitive.Root>
  );
};
