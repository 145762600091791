import type { fabric } from "fabric";
import { createStore } from "../../../../../../../utils/createStore.ts";

export const drawCanvasStore = createStore(
  {
    fabricCanvas: undefined as fabric.Canvas | undefined,
    numObjectsCreated: 0 as number,
    numHistoryActions: 0 as number,
  },
  ({ get, setter }) => ({
    setFabricCanvas: setter("fabricCanvas"),
    addObject: () => {
      setter("numObjectsCreated")(get().numObjectsCreated + 1);
    },
    removeObject: () => {
      setter("numObjectsCreated")(get().numObjectsCreated - 1);
    },
    addActionHistory: () => {
      setter("numHistoryActions")(get().numHistoryActions + 1);
    },
    removeActionHistory: () => {
      setter("numHistoryActions")(get().numHistoryActions - 1);
    },
    clearActionHistory: () => {
      setter("numHistoryActions")(0);
    },
  }),
);
