import { useEffect } from "react";
import { Navigate, Route } from "react-router";
import { Routes } from "react-router-dom";
import {
  ASSETS_WORKSPACE,
  CREATE_WORKSPACE,
  EDIT_WORKSPACE,
} from "../../routes.ts";
import { Header } from "./Header/Header.tsx";
import { StyleImageModal } from "./ImageModal/StyleImageModal.tsx";
import { SettingsMenu } from "./SettingsMenu/SettingsMenu.tsx";
import { tagsFilterStore } from "./stores/tagsFilterStore.ts";
import { ToolMenu } from "./ToolMenu/ToolMenu.tsx";
import { AssetsWorkspace } from "./Workspaces/AssetsWorkspace/AssetsWorkspace.tsx";
import { CreateWorkspace } from "./Workspaces/CreateWorkspace/CreateWorkspace.tsx";
import { generatePromptStore } from "./Workspaces/CreateWorkspace/Tools/GenerateView/stores/generatePromptStore.ts";
import { styleTransferBaseImageStore } from "./Workspaces/CreateWorkspace/Tools/StyleTransferView/stores/styleTransferBaseImageStore.ts";
import { transferPromptStore } from "./Workspaces/CreateWorkspace/Tools/StyleTransferView/stores/transferPromptStore.ts";
import { EditWorkspace } from "./Workspaces/EditWorkspace/EditWorkspace.tsx";
import { ImageStripe } from "./Workspaces/EditWorkspace/ImageStripe/ImageStripe.tsx";
import { imageStripeDisplayStore } from "./Workspaces/EditWorkspace/stores/imageStripeDisplayStore.ts";

export const BoardContent = () => {
  useEffect(() => {
    tagsFilterStore.resetTagsFilter();
    styleTransferBaseImageStore.resetStyleTransferBaseImage();
    generatePromptStore.reset();
    transferPromptStore.reset();
    // XXX: On component unmount, we clear all the states to avoid any deprecated states when switching between boards,
    // such as useless query on previous selected style.
    return () => {
      tagsFilterStore.resetTagsFilter();
      styleTransferBaseImageStore.resetStyleTransferBaseImage();
      generatePromptStore.reset();
      transferPromptStore.reset();
    };
  }, []);

  const isSettingsMenuDisplayable =
    window.location.pathname.includes(CREATE_WORKSPACE) ||
    window.location.pathname.includes(EDIT_WORKSPACE);

  const isStripeImageDisplayable =
    window.location.pathname.includes(EDIT_WORKSPACE);

  const { isImageStripeOpen } = imageStripeDisplayStore.useState();

  return (
    <div className="flex-fill flex-col bg-background">
      <Header />
      <div className="flex-fill flex-row">
        <StyleImageModal />
        <ToolMenu />
        <div className="flex-fill flex-col justify-end">
          <div className="relative flex-fill flex-row">
            {isSettingsMenuDisplayable && <SettingsMenu />}
            <Routes>
              <Route
                path={`${CREATE_WORKSPACE}/*`}
                element={<CreateWorkspace />}
              />
              <Route path={`${EDIT_WORKSPACE}/*`} element={<EditWorkspace />} />
              <Route path={ASSETS_WORKSPACE} element={<AssetsWorkspace />} />
              <Route
                path="*"
                element={<Navigate replace to={CREATE_WORKSPACE} />}
              />
            </Routes>
          </div>
          {isStripeImageDisplayable && isImageStripeOpen && (
            <div className="h-[88px] border-t bg-white">
              <ImageStripe />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
