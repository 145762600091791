import type { fabric } from "fabric";
import { useEffect } from "react";
import { useOn } from "../../../../../../hooks/useOn.ts";
import { FabricCanvas } from "./FabricCanvas.tsx";
import "./history.js";
import { useSelectedAssetUuid } from "../../../../hooks/useSelectedAssetUuid.ts";
import { clearHistory, clearObjects, setBackgroundImage } from "./utils.ts";

export const BaseEditor = ({
  fabricCanvas,
  onCanvasReady,
  initWidth,
  initHeight,
  backgroundImageUrl,
  maxWidth,
  maxHeight,
}: {
  fabricCanvas?: fabric.Canvas;
  initWidth: number;
  initHeight: number;
  onCanvasReady: (canvas: fabric.Canvas) => void;
  maxWidth: number;
  maxHeight: number;
  backgroundImageUrl?: string;
}) => {
  useOn("keydown", ({ key, metaKey, ctrlKey, shiftKey }) => {
    if (fabricCanvas === undefined) return;
    if (key === "z" && (ctrlKey || metaKey) && !shiftKey) {
      // @ts-expect-error history.js is not typed
      fabricCanvas.undo();
    }
    if (key === "z" && (ctrlKey || metaKey) && shiftKey) {
      // @ts-expect-error history.js is not typed
      fabricCanvas.redo();
    }
  });

  const { selectedAssetUuid } = useSelectedAssetUuid();

  useEffect(() => {
    const cancelRef = { canceled: false };
    if (backgroundImageUrl !== undefined && fabricCanvas !== undefined) {
      setBackgroundImage({
        fabricCanvas,
        url: backgroundImageUrl,
        maxWidth,
        maxHeight,
        cancelRef,
      });
    }
    return () => {
      cancelRef.canceled = true;
    };
  }, [fabricCanvas, backgroundImageUrl, maxWidth, maxHeight]);

  useEffect(() => {
    if (fabricCanvas !== undefined) {
      clearObjects(fabricCanvas);
      clearHistory(fabricCanvas);
    }
  }, [fabricCanvas, selectedAssetUuid]);

  return (
    <div>
      <FabricCanvas
        initWidth={initWidth}
        initHeight={initHeight}
        onCanvasReady={(canvas) => {
          onCanvasReady(canvas);
        }}
      />
    </div>
  );
};
