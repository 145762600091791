import {
  DRAW_TOOL,
  DRAW_TOOL_PATH,
  GENERATE_TOOL,
  GENERATE_TOOL_PATH,
  SELECT_TOOL,
  SELECT_TOOL_PATH,
  TRANSFER_TOOL,
  TRANSFER_TOOL_PATH,
  UPSCALE_TOOL,
  UPSCALE_TOOL_PATH,
  VARIATION_TOOL,
  VARIATION_TOOL_PATH,
} from "../../../routes.ts";
import type { Tool } from "../types.ts";

export const useSelectedTool: () => Tool | undefined = () =>
  window.location.pathname.includes(GENERATE_TOOL_PATH)
    ? GENERATE_TOOL
    : window.location.pathname.includes(TRANSFER_TOOL_PATH)
    ? TRANSFER_TOOL
    : window.location.pathname.includes(VARIATION_TOOL_PATH)
    ? VARIATION_TOOL
    : window.location.pathname.includes(DRAW_TOOL_PATH)
    ? DRAW_TOOL
    : window.location.pathname.includes(SELECT_TOOL_PATH)
    ? SELECT_TOOL
    : window.location.pathname.includes(UPSCALE_TOOL_PATH)
    ? UPSCALE_TOOL
    : undefined;
