import classNames from "classnames";
import type { ReactNode } from "react";
import { NavLink } from "react-router-dom";
import { Icon, type IconName } from "../../../../../components/Icon/Icon.tsx";
import { Tooltip } from "../../../../../components/Tooltip/Tooltip.tsx";
import { applyCurrentSearchParamsToPath } from "../../../../../utils/url.ts";

export const ToolMenuButton = ({
  to,
  iconName,
  title,
  tooltipContent,
  isBeta = false,
  onClick,
}: {
  to: string;
  iconName: IconName;
  title: string;
  tooltipContent: ReactNode;
  isBeta?: boolean;
  onClick?: () => void;
}) => (
  <Tooltip
    side="right"
    align="start"
    arrowDisplayed
    delayDuration={500}
    content={tooltipContent}
    className="!bg-gray-600 !rounded-md"
  >
    <div>
      <NavLink
        to={applyCurrentSearchParamsToPath(to)}
        className={({ isActive }) =>
          classNames(
            "flex-col-center w-[72px] gap-md",
            isActive ? "bg-white" : "hover:bg-gray-300",
            isBeta ? "h-[86px]" : "h-[64px]",
          )
        }
        onClick={onClick}
      >
        <Icon name={iconName} size={20} />
        {isBeta ? (
          <div className="flex-col items-center">
            <div>{title}</div>
            <div className="text-pimento-blue font-semibold px-6 rounded-full bg-blue-100">
              Beta
            </div>
          </div>
        ) : (
          <span>{title}</span>
        )}
      </NavLink>
    </div>
  </Tooltip>
);
